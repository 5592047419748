export enum ITRQDocumentType {
    SSN = "SSN",
    ID_FRONT = "ID_FRONT",
    ID_BACK = "ID_BACK",
    SPOUSE_SSN = "SPOUSE_SSN",
    SPOUSE_ID_FRONT = "SPOUSE_ID_FRONT",
    SPOUSE_ID_BACK = "SPOUSE_ID_BACK",

    // INCOME FORMS
    FORM_W_2 = "FORM_W_2",
    FORM_W_2_G = "FORM_W_2_G",
    FORM_1099_MISC = "FORM_1099_MISC",
    FORM_1099_NEC = "FORM_1099_NEC",
    FORM_1099_G = "FORM_1099_G",
    FORM_1099_INT = "FORM_1099_INT",
    FORM_1099_DIV = "FORM_1099_DIV",
    FORM_1099_SSA = "FORM_1099_SSA",
    FORM_1099_R = "FORM_1099_R",
    FORM_1099_B = "FORM_1099_B",
    FORM_1099_K = "FORM_1099_K",
    FORM_K_1 = "FORM_K_1",

    // DEDUCTION FORMS
    FORM_1098 = "FORM_1098",
    FORM_5498_IRA = "FORM_5498_IRA",
    FORM_1098_T = "FORM_1098_T",
    FORM_1098_E = "FORM_1098_E",

    // HEALTH INSURANCE FORMS
    FORM_1095_A = "FORM_1095_A",
    FORM_1095_B = "FORM_1095_B",
    FORM_1095_C = "FORM_1095_C",

    IRS_LETTER = 'IRS_LETTER'

}