export enum ITRQStep {
    TAXPAYER_INFORMATION = 'TAXPAYER_INFORMATION',
    FILING_STATUS = 'FILING_STATUS',
    ADDRESS = 'ADDRESS',
    OCCUPATION = 'OCCUPATION',
    INCOME = 'INCOME',
    ONLINE_MERCHANT_INCOME = 'ONLINE_MERCHANT_INCOME',
    DEDUCTIONS = 'DEDUCTIONS',
    INSURANCE = 'INSURANCE',
    FOREIGN_BANK_ACCOUNT = 'FOREIGN_BANK_ACCOUNT',
    CRYPTO = 'CRYPTO',
    STOCK_TRADE = 'STOCK_TRADE',
    IRS_LETTERS = 'IRS_LETTERS',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    ESTIMATED_TAX_PAYMENTS = 'ESTIMATED_TAX_PAYMENTS',
    SUMMARY = 'SUMMARY'
}

export const ORDERED_STEPS: ITRQStep[] = [
    ITRQStep.TAXPAYER_INFORMATION,
    ITRQStep.FILING_STATUS,
    ITRQStep.ADDRESS,
    ITRQStep.OCCUPATION,
    ITRQStep.INCOME,
    ITRQStep.ONLINE_MERCHANT_INCOME,
    ITRQStep.DEDUCTIONS,
    ITRQStep.INSURANCE,
    ITRQStep.FOREIGN_BANK_ACCOUNT,
    ITRQStep.CRYPTO,
    ITRQStep.STOCK_TRADE,
    ITRQStep.IRS_LETTERS,
    ITRQStep.BANK_ACCOUNT,
    ITRQStep.ESTIMATED_TAX_PAYMENTS,
    ITRQStep.SUMMARY
];