import CreateITRQPayloadDTO from "@/dto/request/itrq/CreateITRQPayloadDTO";
import axios from "axios";
import {INDIVIDUAL_TAXATION_ENDPOINT, INDIVIDUAL_TAXATION_PUBLIC_ENDPOINT} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import ITRQ_DTO from "@/dto/request/itrq/ITRQ_DTO";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AuthFormPayload from "@/dto/archive/AuthFormPayload";

class IndividualTaxationService {

    createQuestionnaire(payload: CreateITRQPayloadDTO) {
        return axios.post(INDIVIDUAL_TAXATION_ENDPOINT, payload, {headers: authHeader()});
    }

    getQuestionnaireByPublicId(publicId: string) {
        return axios.get<ITRQ_DTO>(`${INDIVIDUAL_TAXATION_PUBLIC_ENDPOINT}/${publicId}`);
    }

    updateQuestionnaireByPublicId(publicId: string, payload: ITRQ_DTO) {
        return axios.put(`${INDIVIDUAL_TAXATION_PUBLIC_ENDPOINT}/${publicId}`, payload);
    }

    saveDocumentByPublicId(publicId: string, documentType: ITRQDocumentType, file: FileMetaDTO) {
        return axios.put(`${INDIVIDUAL_TAXATION_PUBLIC_ENDPOINT}/${publicId}/${documentType}`, file)
    }

    getById(id: number) {
        return axios.get<ITRQ_DTO>(`${INDIVIDUAL_TAXATION_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    saveById(id: number, payload: ITRQ_DTO) {
        return axios.put(`${INDIVIDUAL_TAXATION_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

    batch(){
        return axios.put(`${INDIVIDUAL_TAXATION_ENDPOINT}/batch2023`, {},{headers: authHeader()});
    }

    sendAuthForm(payload: AuthFormPayload) {
        return axios.post(`${INDIVIDUAL_TAXATION_ENDPOINT}/auth`, payload, {headers: authHeader()});

    }
}

export default new IndividualTaxationService();